import AdminNavbar from "../../Components/Admin_components/AdminNavbar";
import { useLocation } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import AllTopicsTable from "../../Components/Admin_components/AllTopicsTable";

const AllTopics = () => {
  const { state } = useLocation();
  const chapter_id = state.chapter_id;
  const course_id = state.course_id;
  const course_name = state.name;
  const chapter_name = state.chapter;
  const navigate = useNavigate();

  const goToAllCourses = () => {
    navigate("/chapters", {
      state: {
        id: course_id,
        name: course_name,
      },
    });
  };

  return (
    <div>
      <AdminNavbar />
      <button
        style={{
          padding: "1%",
          borderRadius: "25px",
          margin: "10px",
          zIndex: "999",
          position: "fixed",
          fontSize: "1rem",
          fontWeight: "bolder",
        }}
        onClick={() => goToAllCourses()}
      >
        <ArrowLeftOutlined />
      </button>
      <h1 style={{ textAlign: "center" }}>Course Name : {course_name}</h1>
      <h1 style={{ textAlign: "center" }}>Chapter Name : {chapter_name}</h1>
      <h2 style={{ textAlign: "center" }}>Available Topics</h2>
      <AllTopicsTable
        chapter_id={chapter_id}
        chapter_name={chapter_name}
        course_id={course_id}
        course_name={course_name}
      />
    </div>
  );
};

export default AllTopics;
