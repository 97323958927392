import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import LandingCourse from './Screens/Admin_screens/LandingCouse';
import LoginForm from './Screens/Admin_screens/Login';
import AllChapters from './Screens/Admin_screens/AllChapters';
import AllTopics from './Screens/Admin_screens/AllTopics';
import ContentPage from './Screens/Admin_screens/ContentPage';

function Home() {
  return (
    <div>
      <LoginForm />
    </div>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/admin" element={<LandingCourse />} />
            <Route path="/chapters" element={<AllChapters />} />
            <Route path='/chapters/topics' element={<AllTopics />} />
            <Route path='/chapters/topics/content' element={<ContentPage />} />
          </Routes>
        </header>
      </div>
    </Router>
  );
}

export default App;
