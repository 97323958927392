import React, { useState } from "react";
import { Layout, Row, Col, Button, Spin, message } from "antd";
import {
  MailOutlined,
  LogoutOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import axios from "axios";
import "./AdminNavbar.css";
import { useNavigate } from "react-router-dom";
import pmcurve from "../../Source/pmcurve.png";
import { PMCURVE_LMS_BE_URL } from "../../api";

const { Header } = Layout;

const AdminNavbar = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const Logout = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("access_token");
      const uid = localStorage.getItem("uid");
      const client = localStorage.getItem("client");
      const url =
        `https://${PMCURVE_LMS_BE_URL}/api/v1/auth/sign_out?access-token=` +
        token +
        "&uid=" +
        uid +
        "&client=" +
        client;
      const response = await axios.delete(url);

      if (response.data.success) {
        localStorage.clear();
        navigate("/");
        message.success("Logged out succesfully!");
      }
    } catch (error) {
      console.error(error);
      message.error("Logout failed. Please check again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Header className="admin-navbar ">
        <div
          className="logo "
          onClick={() => {
            navigate("/admin");
          }}
        >
          <img src={pmcurve} alt="pmcurve logo" className="cursor-pointer" />
        </div>
        <Row className="menu" justify="end">
          <Col>
            <span className="menu-item">
              <MailOutlined style={{ marginRight: 8 }} />
              {localStorage.getItem("email")}
            </span>
          </Col>
          <Col>
            <Button
              type="primary"
              icon={<PlayCircleOutlined />}
              style={{ marginLeft: 16 }}
            >
              View Playground
            </Button>
          </Col>
          <Col>
            <Spin spinning={loading} style={{ marginLeft: 16 }}>
              <Button
                type="primary"
                danger
                icon={<LogoutOutlined />}
                style={{ marginLeft: 16 }}
                onClick={Logout}
              >
                {loading ? "Loggin out..." : "Log out"}
              </Button>
            </Spin>
          </Col>
        </Row>
      </Header>
    </Layout>
  );
};

export default AdminNavbar;
