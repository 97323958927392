import React, { useEffect, useState } from "react";
import { Table, Button, Modal, Form, Input, Switch, Spin, message } from "antd";
import axios from "axios";
import { EditOutlined, LoginOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Select } from "antd";
import { PMCURVE_LMS_BE_URL } from "../../api";

const AllChaptersTable = (props) => {
  const course_id = props.course_id;
  const course_name = props.course_name;
  const [loading, setLoading] = useState(false);
  const [chapters, setChapters] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editingCourse, setEditingCourse] = useState(null);
  const { Option } = Select;

  useEffect(() => {
    getAllChapters();
  }, []);

  const getAllChapters = async () => {
    setLoading(true);
    try {
      const url = `https://${PMCURVE_LMS_BE_URL}/api/v1/admins/chapters`;
      const token = localStorage.getItem("Authorization");
      const expiry = localStorage.getItem("expiry");
      const now = new Date();
      const istOffset = 5 * 60 + 30;
      const utcOffset = now.getTimezoneOffset();
      const istTime = new Date(
        now.getTime() + (istOffset + utcOffset) * 60 * 1000
      );
      const istTimestamp = Math.floor(istTime.getTime() / 1000);
      const token_expiry = parseInt(expiry);
      if (
        istTimestamp >= token_expiry ||
        token === undefined ||
        token === null
      ) {
        message.error("Session expired!");
        navigate("/");
      }
      const options = {
        params: { course_id: course_id },
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(url, options);
      if (response.status === 200) {
        setChapters(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Chapter Order",
      dataIndex: "chapterOrder",
      key: "chapterOrder",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Chapter name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Chapter status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Accessibility Type",
      dataIndex: "accessibilityType",
      key: "accessibilityType",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
            style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
          >
            Edit
          </Button>
          <Button
            icon={<LoginOutlined />}
            onClick={() => navigateToTopics(record)}
            style={{
              fontFamily: "Arial, sans-serif",
              fontSize: "16px",
              marginLeft: "4px",
            }}
          >
            View Topics
          </Button>
          <Button
            icon={<LoginOutlined />}
            onClick={() => handleDeleteChapter(record)}
            style={{
              fontFamily: "Arial, sans-serif",
              fontSize: "16px",
              marginLeft: "4px",
              color: "red",
              borderColor: "red",
            }}
          >
            Delete Chapter
          </Button>
        </>
      ),
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const navigateToTopics = (record) => {
    const id = record.id;
    const token = localStorage.getItem("Authorization");
    const expiry = localStorage.getItem("expiry");
    const now = new Date();
    const istOffset = 5 * 60 + 30;
    const utcOffset = now.getTimezoneOffset();
    const istTime = new Date(
      now.getTime() + (istOffset + utcOffset) * 60 * 1000
    );
    const istTimestamp = Math.floor(istTime.getTime() / 1000);
    const token_expiry = parseInt(expiry);
    if (istTimestamp >= token_expiry || token === undefined || token === null) {
      message.error("Session expired!");
      navigate("/");
    }
    navigate("/chapters/topics", {
      state: {
        chapter_id: record.id,
        course_id: course_id,
        name: course_name,
        chapter: record.name,
      },
    });
  };

  const handleEdit = (record) => {
    setEditingCourse(record);
    setIsEditModalVisible(true);
    form.setFieldsValue({
      name: record.name,
      chapter_order: record.chapterOrder,
      paid: record.accessibilityType === "paid",
      status: record.status,
    });
  };

  const handleDeleteChapter = async (record) => {
    const id = record.id;
    try {
      // const values = await form.validateFields();
      const token = localStorage.getItem("Authorization");
      const expiry = localStorage.getItem("expiry");
      const now = new Date();
      const istOffset = 5 * 60 + 30;
      const utcOffset = now.getTimezoneOffset();
      const istTime = new Date(
        now.getTime() + (istOffset + utcOffset) * 60 * 1000
      );
      const istTimestamp = Math.floor(istTime.getTime() / 1000);
      const token_expiry = parseInt(expiry);
      if (
        istTimestamp >= token_expiry ||
        token === undefined ||
        token === null
      ) {
        message.error("Session expired!");
        navigate("/");
      }

      const response = await axios.delete(
        `https://${PMCURVE_LMS_BE_URL}/api/v1/admins/chapters/${id}`,
        {
          headers: { Authorization: token },
        }
      );

      if (response.status === 200 || response.status === 201) {
        message.success("Chapter deleted successfully!");
        setIsModalVisible(false);
        form.resetFields();
        getAllChapters();
      }
    } catch (error) {
      console.error("Error:", error);
      message.error("Failed to delete Chapter. Please try again.");
    }
  };

  const handleOk = async (values) => {
    try {
      // const values = await form.validateFields();
      const token = localStorage.getItem("Authorization");
      const expiry = localStorage.getItem("expiry");
      const now = new Date();
      const istOffset = 5 * 60 + 30;
      const utcOffset = now.getTimezoneOffset();
      const istTime = new Date(
        now.getTime() + (istOffset + utcOffset) * 60 * 1000
      );
      const istTimestamp = Math.floor(istTime.getTime() / 1000);
      const token_expiry = parseInt(expiry);
      if (
        istTimestamp >= token_expiry ||
        token === undefined ||
        token === null
      ) {
        message.error("Session expired!");
        navigate("/");
      }

      const chapterData = {
        name: values.name,
        chapter_order: values.chapter_order,
        course_id: course_id,
        accessibility_type: values.paid ? "paid" : "free",
      };

      const response = await axios.post(
        `https://${PMCURVE_LMS_BE_URL}/api/v1/admins/chapters`,
        chapterData,
        {
          headers: { Authorization: token },
        }
      );

      if (response.status === 200 || response.status === 201) {
        message.success("chapter added successfully!");
        setIsModalVisible(false);
        form.resetFields();
        getAllChapters();
      }
    } catch (error) {
      console.error("Error:", error);
      message.error("Failed to add course. Please try again.");
    }
  };

  const handleUpdate = async (values) => {
    setLoading(true);
    try {
      const token = localStorage.getItem("Authorization");
      const expiry = localStorage.getItem("expiry");
      const now = new Date();
      const istOffset = 5 * 60 + 30;
      const utcOffset = now.getTimezoneOffset();
      const istTime = new Date(
        now.getTime() + (istOffset + utcOffset) * 60 * 1000
      );
      const istTimestamp = Math.floor(istTime.getTime() / 1000);
      const token_expiry = parseInt(expiry);
      if (
        istTimestamp >= token_expiry ||
        token === undefined ||
        token === null
      ) {
        message.error("Session expired!");
        navigate("/");
      }

      const courseData = {
        name: values.name,
        chapter_order: values.chapter_order,
        accessibility_type: values.paid ? "paid" : "free",
        status: values.status,
      };

      const response = await axios.put(
        `https://${PMCURVE_LMS_BE_URL}/api/v1/admins/chapters/${editingCourse.id}`,
        courseData,
        {
          headers: { Authorization: token },
        }
      );

      if (response.status === 200) {
        message.success("Chapter updated successfully!");
        setIsEditModalVisible(false);
        setEditingCourse(null);
        form.resetFields();
        getAllChapters();
      }
    } catch (error) {
      console.error("Error:", error);
      message.error("Failed to update chapter. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsEditModalVisible(false);
    setEditingCourse(null);
    form.resetFields();
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "20px",
        }}
      >
        <Button
          type="primary"
          onClick={showModal}
          style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
        >
          Add a New Chapter
        </Button>
      </div>
      <Spin spinning={loading}>
        <Table columns={columns} dataSource={chapters} />
      </Spin>
      <Modal
        title="Add a New Chapter"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null} // No default footer buttons
        style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
      >
        <Form
          layout="vertical"
          onFinish={handleOk}
          style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
        >
          <Form.Item
            label="Chapter Name"
            name="name"
            rules={[
              { required: true, message: "Please input the chapter name!" },
            ]}
            style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="paid"
            name="paid"
            valuePropName="checked"
            style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label="Chapter order"
            name="chapter_order"
            rules={[
              { required: true, message: "Please input the chapter order!" },
            ]}
            style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                marginRight: "10px",
                fontFamily: "Arial, sans-serif",
                fontSize: "16px",
              }}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Edit Chapter"
        visible={isEditModalVisible}
        onCancel={handleCancel}
        footer={null}
        style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleUpdate}
          style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
        >
          <Form.Item
            label="Chapter Name"
            name="name"
            rules={[
              { required: true, message: "Please input the chapter name!" },
            ]}
            style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Paid"
            name="paid"
            valuePropName="checked"
            style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label="Chapter Order"
            name="chapter_order"
            rules={[
              {
                required: true,
                message: "Please input the new chapter order for this chapter!",
              },
            ]}
            style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Chapter Status"
            name="status"
            rules={[
              { required: true, message: "Please select the current status!" },
            ]}
            style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
          >
            <Select placeholder="Select a status">
              <Option value="active">Active</Option>
              <Option value="inactive">Inactive</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                marginRight: "10px",
                fontFamily: "Arial, sans-serif",
                fontSize: "16px",
              }}
            >
              Update
            </Button>
            <Button
              onClick={handleCancel}
              style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AllChaptersTable;
