import React from 'react';
import { Table } from 'antd';

const columns = [
  {
    title: 'User Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'No of Paid courses',
    dataIndex: 'paidUsers',
    key: 'paidUsers',
  },
  {
    title: 'No of Free Courses',
    dataIndex: 'freeUsers',
    key: 'freeUsers',
  },
];

const data = [
  {
    key: '1',
    name: 'User 1',
    status: 'Active',
    paidUsers: 10,
    freeUsers: 1,
  },
  {
    key: '2',
    name: 'User 2',
    status: 'Active',
    paidUsers: 10,
    freeUsers: 10,
  },
  {
    key: '3',
    name: 'User 3',
    status: 'Inactive',
    paidUsers: 20,
    freeUsers: 5,
  },
];

const AllUsers = () => {
  return (
    <div
      id="courses"
      style={{
        width: '100vw',
        padding: '20px',
        textAlign: 'center',
        background: 'rgba(0,255,0,0.02)',
      }}
    >
      <h1>All Users</h1>
      <Table columns={columns} dataSource={data} />
    </div>
  );
};

export default AllUsers;
