import React, { useEffect, useState } from "react";
import { Table, Button, Modal, Form, Input, Switch, Spin, message } from "antd";
import axios from "axios";
import { EditOutlined, LoginOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { PMCURVE_LMS_BE_URL } from "../../api";

const AllTopicsTable = (props) => {
  const chapter_id = props.chapter_id;
  const chapter_name = props.chapter_name;
  const course_name = props.course_name;
  const course_id = props.course_id;
  const [loading, setLoading] = useState(false);
  const [topics, setTopics] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editingTopic, setEditingTopic] = useState(null);

  // console.log(id);

  useEffect(() => {
    getAllTopics();
  }, []);

  const getAllTopics = async () => {
    setLoading(true);
    try {
      const url = `https://${PMCURVE_LMS_BE_URL}/api/v1/admins/topics`;
      const token = localStorage.getItem("Authorization");
      const expiry = localStorage.getItem("expiry");
      const now = new Date();
      const istOffset = 5 * 60 + 30;
      const utcOffset = now.getTimezoneOffset();
      const istTime = new Date(
        now.getTime() + (istOffset + utcOffset) * 60 * 1000
      );
      const istTimestamp = Math.floor(istTime.getTime() / 1000);
      const token_expiry = parseInt(expiry);
      if (
        istTimestamp >= token_expiry ||
        token === undefined ||
        token === null
      ) {
        message.error("Session expired!");
        navigate("/");
      }
      const options = {
        params: { chapter_id: chapter_id },
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(url, options);
      if (response.status === 200) {
        setTopics(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Topic Order",
      dataIndex: "topicOrder",
      key: "topicOrder",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Topic name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
            style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
          >
            Edit
          </Button>
          <Button
            icon={<LoginOutlined />}
            onClick={() => navigateToTopics(record)}
            style={{
              fontFamily: "Arial, sans-serif",
              fontSize: "16px",
              marginLeft: "4px",
            }}
          >
            Open this topic
          </Button>
          <Button
            icon={<LoginOutlined />}
            onClick={() => {
              handleDeleteTopic(record);
            }}
            style={{
              fontFamily: "Arial, sans-serif",
              fontSize: "16px",
              marginLeft: "4px",
              color: "red",
            }}
          >
            Delete this topic
          </Button>
        </>
      ),
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const navigateToTopics = (record) => {
    const id = record.id;
    const token = localStorage.getItem("Authorization");
    const expiry = localStorage.getItem("expiry");
    const now = new Date();
    const istOffset = 5 * 60 + 30;
    const utcOffset = now.getTimezoneOffset();
    const istTime = new Date(
      now.getTime() + (istOffset + utcOffset) * 60 * 1000
    );
    const istTimestamp = Math.floor(istTime.getTime() / 1000);
    const token_expiry = parseInt(expiry);
    if (istTimestamp >= token_expiry || token === undefined || token === null) {
      message.error("Session expired!");
      navigate("/");
    }
    navigate("/chapters/topics/content", {
      state: {
        topic_id: record.id,
        topic_name: record.name,
        chapter_name: chapter_name,
        course_name: course_name,
        chapter_id: chapter_id,
        course_id: course_id,
      },
    });
  };

  const handleDeleteTopic = async (record) => {
    const id = record.id;
    try {
      // const values = await form.validateFields();
      const token = localStorage.getItem("Authorization");
      const expiry = localStorage.getItem("expiry");
      const now = new Date();
      const istOffset = 5 * 60 + 30;
      const utcOffset = now.getTimezoneOffset();
      const istTime = new Date(
        now.getTime() + (istOffset + utcOffset) * 60 * 1000
      );
      const istTimestamp = Math.floor(istTime.getTime() / 1000);
      const token_expiry = parseInt(expiry);
      if (
        istTimestamp >= token_expiry ||
        token === undefined ||
        token === null
      ) {
        message.error("Session expired!");
        navigate("/");
      }

      const topicData = {
        // name: values.name,
        // topic_order: values.topic_order,
        course_id: course_id,
        chapter_id: chapter_id,
      };

      const response = await axios.delete(
        `https://${PMCURVE_LMS_BE_URL}/api/v1/admins/topics/${id}`,
        {
          headers: { Authorization: token },
        }
      );

      if (response.status === 200 || response.status === 201) {
        message.success("Topic deleted successfully!");
        setIsModalVisible(false);
        form.resetFields();
        getAllTopics();
      }
    } catch (error) {
      console.error("Error:", error);
      message.error("Failed to delete Topic. Please try again.");
    }
  };

  const handleEdit = (record) => {
    setEditingTopic(record);
    setIsEditModalVisible(true);
    form.setFieldsValue({
      name: record.name,
      topic_order: record.topicOrder,
    });
  };

  const handleOk = async (values) => {
    try {
      // const values = await form.validateFields();
      const token = localStorage.getItem("Authorization");
      const expiry = localStorage.getItem("expiry");
      const now = new Date();
      const istOffset = 5 * 60 + 30;
      const utcOffset = now.getTimezoneOffset();
      const istTime = new Date(
        now.getTime() + (istOffset + utcOffset) * 60 * 1000
      );
      const istTimestamp = Math.floor(istTime.getTime() / 1000);
      const token_expiry = parseInt(expiry);
      if (
        istTimestamp >= token_expiry ||
        token === undefined ||
        token === null
      ) {
        message.error("Session expired!");
        navigate("/");
      }

      const topicData = {
        name: values.name,
        topic_order: values.topic_order,
        course_id: course_id,
        chapter_id: chapter_id,
      };

      const response = await axios.post(
        `https://${PMCURVE_LMS_BE_URL}/api/v1/admins/topics`,
        topicData,
        {
          headers: { Authorization: token },
        }
      );

      if (response.status === 200 || response.status === 201) {
        message.success("Topic added successfully!");
        setIsModalVisible(false);
        form.resetFields();
        getAllTopics();
      }
    } catch (error) {
      console.error("Error:", error);
      message.error("Failed to add Topic. Please try again.");
    }
  };

  const handleUpdate = async (values) => {
    setLoading(true);
    try {
      const token = localStorage.getItem("Authorization");
      const expiry = localStorage.getItem("expiry");
      const now = new Date();
      const istOffset = 5 * 60 + 30;
      const utcOffset = now.getTimezoneOffset();
      const istTime = new Date(
        now.getTime() + (istOffset + utcOffset) * 60 * 1000
      );
      const istTimestamp = Math.floor(istTime.getTime() / 1000);
      const token_expiry = parseInt(expiry);
      if (
        istTimestamp >= token_expiry ||
        token === undefined ||
        token === null
      ) {
        message.error("Session expired!");
        navigate("/");
      }

      const topicData = {
        name: values.name,
        topic_order: values.topic_order,
      };

      const response = await axios.put(
        `https://${PMCURVE_LMS_BE_URL}/api/v1/admins/topics/${editingTopic.id}`,
        topicData,
        {
          headers: { Authorization: token },
        }
      );

      if (response.status === 200) {
        message.success("Topic updated successfully!");
        setIsEditModalVisible(false);
        setEditingTopic(null);
        form.resetFields();
        getAllTopics();
      }
    } catch (error) {
      console.error("Error:", error);
      message.error("Failed to update topic. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsEditModalVisible(false);
    setEditingTopic(null);
    form.resetFields();
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "20px",
        }}
      >
        <Button
          type="primary"
          onClick={showModal}
          style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
        >
          Add a New Topic
        </Button>
      </div>
      <Spin spinning={loading}>
        <Table columns={columns} dataSource={topics} />
      </Spin>
      <Modal
        title="Add a New topic"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null} // No default footer buttons
        style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
      >
        <Form
          layout="vertical"
          onFinish={handleOk}
          style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
        >
          <Form.Item
            label="Topic Name"
            name="name"
            rules={[
              { required: true, message: "Please input the Topic name!" },
            ]}
            style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Topic order"
            name="topic_order"
            rules={[
              { required: true, message: "Please input the topic order!" },
            ]}
            style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                marginRight: "10px",
                fontFamily: "Arial, sans-serif",
                fontSize: "16px",
              }}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Edit Topic"
        visible={isEditModalVisible}
        onCancel={handleCancel}
        footer={null}
        style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleUpdate}
          style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
        >
          <Form.Item
            label="Topic Name"
            name="name"
            rules={[
              { required: true, message: "Please input the chapter name!" },
            ]}
            style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Topic Order"
            name="topic_order"
            rules={[
              {
                required: true,
                message: "Please input the new chapter order for this chapter!",
              },
            ]}
            style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                marginRight: "10px",
                fontFamily: "Arial, sans-serif",
                fontSize: "16px",
              }}
            >
              Update
            </Button>
            <Button
              onClick={handleCancel}
              style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AllTopicsTable;
