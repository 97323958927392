import AdminNavbar from "../../Components/Admin_components/AdminNavbar";
import { useLocation } from "react-router-dom";
import AllChaptersTable from "../../Components/Admin_components/AllChaptersTable";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';



const AllChapters = () =>{
    const { state } = useLocation();
    const course_id = state.id;
    const course_name = state.name;
    const navigate = useNavigate();

    const goToAllCourses = () => {
        navigate("/admin")
    }

    return (
        <div>
            <AdminNavbar />
            <button style={{padding: "1%", borderRadius: "25px", margin: "10px", zIndex: "999", position: "fixed", fontSize: "1rem", fontWeight: "bolder"}} onClick={() => goToAllCourses()}><ArrowLeftOutlined /></button>
            <h1 style={{textAlign:"center"}}>Course Name : {course_name}</h1>
            <h2 style={{textAlign:"center"}}>Available chapters</h2>
            <AllChaptersTable course_id={course_id} course_name = {course_name}/>
        </div>
    );
}

export default AllChapters;