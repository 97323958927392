import React, { useState } from "react";
import AdminNavbar from "../../Components/Admin_components/AdminNavbar";
import { Anchor } from "antd";
import AllCourses from "../../Components/Admin_components/AllCourses";
import AllUsers from "../../Components/Admin_components/AllUsers";
import "./LandingCourse.css";

const { Link } = Anchor;

const LandingCourse = () => {
  const [activeTab, setActiveTab] = useState("courses");

  const handleTabChange = (hash) => {
    if (hash === "#courses") {
      setActiveTab("courses");
    } else if (hash === "#users") {
      setActiveTab("users");
    }
  };

  return (
    <div>
      <AdminNavbar />
      <div className="anchor-container">
        <Anchor
          direction="horizontal"
          affix={false}
          showInkInFixed
          onChange={handleTabChange}
        >
          <Link href="#courses" title="Courses" />
          <Link href="#users" title="Users" />
        </Anchor>
      </div>
      <div style={{ height: "100vh", overflowY: "scroll" }}>
        {activeTab === "courses" && <AllCourses />}
        {activeTab === "users" && <AllUsers />}
      </div>
    </div>
  );
};

export default LandingCourse;
