import React, { useEffect, useState } from "react";
// import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Switch,
  Spin,
  message,
  Upload,
} from "antd";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { EditOutlined, LoginOutlined, UploadOutlined } from "@ant-design/icons";
import { PMCURVE_LMS_BE_URL } from "../../api";

const toolbarOptions = [
  [{ font: [] }],
  [{ size: ["small", false, "large", "huge"] }],
  ["bold", "italic", "underline", "strike"],
  [{ color: [] }, { background: [] }],
  [{ script: "sub" }, { script: "super" }],
  [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
  [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
  [{ direction: "rtl" }],
  ["link", "image", "video"],
  ["clean"],
];

const AllCourses = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [uploadId, setUploadId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  // const [editorContent, setEditorContent] = useState('');
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingCourse, setEditingCourse] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { Option } = Select;

  useEffect(() => {
    getAllCourses();
  }, []);

  const columns = [
    {
      title: "Course Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Accessibility Type",
      dataIndex: "accessibilityType",
      key: "accessibilityType",
    },
    {
      title: "Price (INR)",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Subscription Type",
      dataIndex: "subscriptionType",
      key: "subscriptionType",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
            style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
          >
            Edit
          </Button>
          <Button
            icon={<LoginOutlined />}
            onClick={() => navigateToChapters(record)}
            style={{
              fontFamily: "Arial, sans-serif",
              fontSize: "16px",
              marginLeft: "4px",
            }}
          >
            View Chapters
          </Button>
          <Button
            icon={<UploadOutlined />}
            onClick={() => uploadImage(record)}
            style={{
              fontFamily: "Arial, sans-serif",
              fontSize: "16px",
              marginLeft: "4px",
            }}
          >
            Upload image
          </Button>
        </>
      ),
    },
  ];

  const navigateToChapters = (record) => {
    const id = record.id;
    const token = localStorage.getItem("Authorization");
    const expiry = localStorage.getItem("expiry");
    const now = new Date();
    const istOffset = 5 * 60 + 30;
    const utcOffset = now.getTimezoneOffset();
    const istTime = new Date(
      now.getTime() + (istOffset + utcOffset) * 60 * 1000
    );
    const istTimestamp = Math.floor(istTime.getTime() / 1000);
    const token_expiry = parseInt(expiry);
    if (istTimestamp >= token_expiry || token === undefined || token === null) {
      message.error("Session expired!");
      navigate("/");
    }
    navigate("/chapters", {
      state: {
        id: id,
        name: record.name,
      },
    });
  };
  const getAllCourses = async () => {
    setLoading(true);
    try {
      const url = `https://${PMCURVE_LMS_BE_URL}/api/v1/admins/courses`;
      const token = localStorage.getItem("Authorization");
      const expiry = localStorage.getItem("expiry");
      const now = new Date();
      const istOffset = 5 * 60 + 30;
      const utcOffset = now.getTimezoneOffset();
      const istTime = new Date(
        now.getTime() + (istOffset + utcOffset) * 60 * 1000
      );
      const istTimestamp = Math.floor(istTime.getTime() / 1000);
      const token_expiry = parseInt(expiry);
      if (
        istTimestamp >= token_expiry ||
        token === undefined ||
        token === null
      ) {
        message.error("Session expired!");
        navigate("/");
      }
      const response = await axios.get(url, {
        headers: { Authorization: token },
      });
      if (response.status === 200) {
        setCourses(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const uploadImage = (record) => {
    console.log(record.bannerLink);
    const currentImageUrl = record?.bannerLink
      ? `https://${PMCURVE_LMS_BE_URL}` + record.bannerLink
      : "No image found";
    setImageUrl(currentImageUrl);
    setUploadId(record.id);
    setIsUploadModalVisible(true);
  };

  const uploadProps = {
    beforeUpload: (file) => {
      const formData = new FormData();
      formData.append("banner", file);

      const token = localStorage.getItem("Authorization");
      setLoading(true);

      axios
        .put(
          `https://${PMCURVE_LMS_BE_URL}/api/v1/admins/courses/${uploadId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: token,
            },
          }
        )
        .then((response) => {
          console.log(response);
          handleCancel();
          getAllCourses();
        })
        .catch((error) => {
          console.error("Failed to upload image: ", error);
          message.error("Failed to upload image. Please try again.");
        })
        .finally(() => {
          setLoading(false);
        });

      return false;
    },
  };

  const handleEdit = (record) => {
    setEditingCourse(record);
    setIsEditModalVisible(true);
    form.setFieldsValue({
      name: record.name,
      details: record.description,
      paid: record.accessibilityType === "paid",
      price: record.price,
      subscription: record.subscriptionType,
      status: record.status,
    });
  };

  const handleOk = async (values) => {
    try {
      // const values = await form.validateFields();
      const token = localStorage.getItem("Authorization");
      const expiry = localStorage.getItem("expiry");
      const now = new Date();
      const istOffset = 5 * 60 + 30;
      const utcOffset = now.getTimezoneOffset();
      const istTime = new Date(
        now.getTime() + (istOffset + utcOffset) * 60 * 1000
      );
      const istTimestamp = Math.floor(istTime.getTime() / 1000);
      const token_expiry = parseInt(expiry);
      if (
        istTimestamp >= token_expiry ||
        token === undefined ||
        token === null
      ) {
        message.error("Session expired!");
        navigate("/");
      }

      const courseData = {
        name: values.name,
        description: values.details,
        accessibility_type: values.paid ? "paid" : "free",
        price: values.paid ? values.price : 0,
        subscription_type: values.subscription,
      };

      const response = await axios.post(
        `https://${PMCURVE_LMS_BE_URL}/api/v1/admins/courses`,
        courseData,
        {
          headers: { Authorization: token },
        }
      );

      if (response.status === 200 || response.status === 201) {
        message.success("Course added successfully!");
        setIsModalVisible(false);
        form.resetFields();
        // setEditorContent('');
        getAllCourses();
      }
    } catch (error) {
      console.error("Error:", error);
      message.error("Failed to add course. Please try again.");
    }
  };

  const handleUpdate = async (values) => {
    setLoading(true);
    try {
      const token = localStorage.getItem("Authorization");
      const expiry = localStorage.getItem("expiry");
      const now = new Date();
      const istOffset = 5 * 60 + 30;
      const utcOffset = now.getTimezoneOffset();
      const istTime = new Date(
        now.getTime() + (istOffset + utcOffset) * 60 * 1000
      );
      const istTimestamp = Math.floor(istTime.getTime() / 1000);
      const token_expiry = parseInt(expiry);
      if (
        istTimestamp >= token_expiry ||
        token === undefined ||
        token === null
      ) {
        message.error("Session expired!");
        navigate("/");
      }

      const courseData = {
        name: values.name,
        description: values.details,
        accessibility_type: values.paid ? "paid" : "free",
        price: values.paid ? values.price : 0,
        subscription_type: values.subscription,
        status: values.status,
      };

      const response = await axios.put(
        `https://${PMCURVE_LMS_BE_URL}/api/v1/admins/courses/${editingCourse.id}`,
        courseData,
        {
          headers: { Authorization: token },
        }
      );

      if (response.status === 200) {
        message.success("Course updated successfully!");
        setIsEditModalVisible(false);
        setEditingCourse(null);
        form.resetFields();
        getAllCourses();
      }
    } catch (error) {
      console.error("Error:", error);
      message.error("Failed to update course. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsEditModalVisible(false);
    setIsUploadModalVisible(false);
    setEditingCourse(null);
    form.resetFields();
  };

  const filteredCourses = courses.filter((course) => {
    const regex = new RegExp(searchTerm, "i"); // 'i' for case-insensitive
    return regex.test(course.name);
  });

  // const handleEditorChange = (content) => {
  //   setEditorContent(content);
  // };

  return (
    <div
      id="courses"
      style={{
        width: "97vw",
        padding: "20px",
        textAlign: "center",
        background: "rgba(0,255,0,0.02)",
        fontFamily: "Arial, sans-serif",
        fontSize: "16px",
        height: "100vh",
      }}
    >
      <h1>All Courses</h1>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "20px",
        }}
      >
        <Button
          type="primary"
          onClick={showModal}
          style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
        >
          Add a New Course
        </Button>
      </div>
      <div style={{ marginBottom: "20px" }}>
        <Input
          placeholder="Search courses by name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: 300, marginRight: "10px" }}
        />
      </div>

      <Spin spinning={loading}>
        <Table columns={columns} dataSource={filteredCourses} />
      </Spin>
      <Modal
        title="Add a New Course"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null} // No default footer buttons
        style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
      >
        <Form
          layout="vertical"
          onFinish={handleOk}
          style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
        >
          <Form.Item
            label="Course Name"
            name="name"
            rules={[
              { required: true, message: "Please input the course name!" },
            ]}
            style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="paid"
            name="paid"
            valuePropName="checked"
            style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label="Price (INR)"
            name="price"
            rules={[{ required: true, message: "Please input the price!" }]}
            style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Course Details"
            name="details"
            rules={[
              { required: true, message: "Please input the course details!" },
            ]}
            style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
          >
            <Input />
            {/* <ReactQuill
              value={editorContent}
              onChange={handleEditorChange}
              modules={{ toolbar: toolbarOptions }}
              formats={[
                'font', 'size',
                'bold', 'italic', 'underline', 'strike',
                'color', 'background',
                'script',
                'header', 'blockquote', 'code-block',
                'list', 'bullet', 'indent',
                'direction',
                'link', 'image', 'video',
              ]}
            /> */}
          </Form.Item>
          <Form.Item
            label="Subscription Period"
            name="subscription"
            rules={[
              {
                required: true,
                message: "Please select the subscription period!",
              },
            ]}
            style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
          >
            <Select placeholder="Select a period">
              <Option value="monthly">1 month</Option>
              <Option value="quarterly">3 months</Option>
              <Option value="half_yearly">6 months</Option>
              <Option value="yearly">1 year</Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                marginRight: "10px",
                fontFamily: "Arial, sans-serif",
                fontSize: "16px",
              }}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Image upload for course"
        visible={isUploadModalVisible}
        onCancel={handleCancel}
        footer={null}
        style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
      >
        <Form layout="vertical">
          <Form.Item label="Current Image">
            {imageUrl && (
              <img
                src={imageUrl}
                alt="Current"
                style={{ width: "100%", height: "auto" }}
              />
            )}
          </Form.Item>
          <Form.Item label="Upload New Image">
            <Upload {...uploadProps}>
              <Button icon={<UploadOutlined />}>Select Image</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button
              onClick={handleCancel}
              style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Edit Course"
        visible={isEditModalVisible}
        onCancel={handleCancel}
        footer={null}
        style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleUpdate}
          style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
        >
          <Form.Item
            label="Course Name"
            name="name"
            rules={[
              { required: true, message: "Please input the course name!" },
            ]}
            style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Paid"
            name="paid"
            valuePropName="checked"
            style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label="Price (INR)"
            name="price"
            rules={[{ required: true, message: "Please input the price!" }]}
            style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Course Details"
            name="details"
            rules={[
              { required: true, message: "Please input the course details!" },
            ]}
            style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Subscription Period"
            name="subscription"
            rules={[
              {
                required: true,
                message: "Please select the subscription period!",
              },
            ]}
            style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
          >
            <Select placeholder="Select a period">
              <Option value="monthly">1 month</Option>
              <Option value="quarterly">3 months</Option>
              <Option value="half_yearly">6 months</Option>
              <Option value="yearly">1 year</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Course Status"
            name="status"
            rules={[
              { required: true, message: "Please select the current status!" },
            ]}
            style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
          >
            <Select placeholder="Select a status">
              <Option value="draft">Draft</Option>
              <Option value="active">Active</Option>
              <Option value="inactive">Inactive</Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                marginRight: "10px",
                fontFamily: "Arial, sans-serif",
                fontSize: "16px",
              }}
            >
              Update
            </Button>
            <Button
              onClick={handleCancel}
              style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AllCourses;
