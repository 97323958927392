import React, { useState } from "react";
import { Form, Input, Button, Typography, Card, message, Spin } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { PMCURVE_LMS_BE_URL } from "../../api";

const { Title } = Typography;

const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `https://${PMCURVE_LMS_BE_URL}/api/v1/auth/sign_in`,
        {
          email: values.email,
          password: values.password,
        }
      );

      if (response.status === 200) {
        const data = response.data;

        localStorage.setItem("email", data.data.email);
        localStorage.setItem("first_name", data.data.first_name);
        localStorage.setItem("last_name", data.data.last_name);
        localStorage.setItem("avatar_url", data.data.avatar_url);
        localStorage.setItem("expiry", response.data.headers.expiry);
        localStorage.setItem(
          "Authorization",
          response.data.headers.Authorization
        );
        localStorage.setItem("uid", response.data.headers.uid);
        localStorage.setItem("client", response.data.headers.client);
        localStorage.setItem(
          "access_token",
          response.data.headers["access-token"]
        );
        navigate("/admin");
        const name = data.data.first_name + " " + data.data.last_name + " ";
        message.success(name + "Login successful!");
      } else {
        message.error("Login failed. Please check your credentials.");
      }
    } catch (error) {
      console.error("Error:", error);
      message.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        background: "#f0f2f5",
      }}
    >
      <Card
        style={{
          width: 400,
          padding: "20px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Title level={2} style={{ textAlign: "center", marginBottom: "20px" }}>
          pmcurve Admin
        </Title>
        <Spin spinning={loading}>
          <Form
            name="login"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            layout="vertical"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Log in
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Card>
    </div>
  );
};

export default LoginForm;
